import React from "react";
import Header from "./header";
import Footer from "./footer";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

const Layout = (props) => {
  return (
    <>
      <main className="bg-gray-900">
        <aside className="h-12 w-screen bg-red-500/80 flex flex-col items-center justify-center">
          <ul className="flex flex-row items-center space-x-6">
            <ExclamationCircleIcon className="h-6 text-white/70"/>
            <li className="text-white/70 text-sm">We've Suspended Test Rides due to the Increasing Cases of COVID-19</li>
            <ExclamationCircleIcon className="h-6 text-white/70"/>
          </ul>
        </aside>
        <Header />
        <main className="mt-2">{props.children}</main>
        <Footer />
      </main>
    </>
  );
};

export default Layout;
