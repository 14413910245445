import { Link } from "gatsby";
import React from "react";
import LogoSVG from "./kmLogo";

const Footer = () => {
  return (
    <>
      <footer className="w-screen py-8 bg-gray-800 flex flex-col space-y-6 items-center">
        <div>
          <ul className="flex flex-row space-x-5 flex-wrap space-y-4 justify-center px-4 md:px-0">
            <Link to="/scooters/intercity-aeolus">
              <li className="text-base font-light hover:underline hover:text-blue-500 text-gray-400 text-center align-middle my-auto">Scooter</li>
            </Link>
            <Link to="/">
              <li className="text-base font-light hover:underline hover:text-blue-500 text-gray-400 text-center align-middle my-auto">Bikes</li>
            </Link>
            <Link to="/dealers">
              <li className="text-base font-light hover:underline hover:text-blue-500 text-gray-400 text-center align-middle my-auto">Dealers</li>
            </Link>
            <Link to="/test-rides">
              <li className="text-base font-light hover:underline hover:text-blue-500 text-gray-400 text-center align-middle my-auto">Test Rides</li>
            </Link>
            <Link to="/press">
              <li className="text-base font-light hover:underline hover:text-blue-500 text-gray-400 text-center align-middle my-auto">Press</li>
            </Link>
            <Link to="/contact-us">
              <li className="text-base font-light hover:underline hover:text-blue-500 text-gray-400 text-center align-middle my-auto">Contact Us</li>
            </Link>
          </ul>
        </div>
        <ul className="flex flex-col space-y-2 items-center justify-center">
          <div className="text-sm text-gray-500">
            ©Kabira Mobility Pvt. Ltd. 2021. All Rights Reserved
          </div>
          <div>
            <ul className="flex flex-row space-x-5">
              <Link to="/privacy-policy">
                <li className="text-sm font-light hover:underline hover:text-blue-500 text-gray-400">Privacy</li>
              </Link>
              <Link to="/terms">
                <li className="text-sm font-light hover:underline hover:text-blue-500 text-gray-400">Terms</li>
              </Link>
              <Link to="/refund-policy">
                <li className="text-sm font-light hover:underline hover:text-blue-500 text-gray-400">Refund</li>
              </Link>
          
            </ul>
          </div>
        </ul>
      </footer>
    </>
  );
};

export default Footer;
