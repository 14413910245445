import React from "react";
import { Fragment, useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import { Popover, Transition, Dialog } from "@headlessui/react";
import LogoSVG from "./kmLogo";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHeader {
        data {
          header_item {
            link_to
            navigation_item {
              text
            }
          }
        }
      }
    }
  `);

  const [open, setOpen] = useState(false);

  return (
    <>
      <nav className="w-full px-2 lg:px-3 pb-0 pt-2 lg:pt-3 sticky top-0 z-30">
        <div className="bg-gray-700 rounded flex flex-row p-4 justify-between items-center shadow-md">
          {/* Logo */}
          <div className="flex-1">
            <div className = "flex flex-row items-start">
              <Link to="/">
                <LogoSVG />
              </Link>
            </div>
          </div>
          <ul className="md:flex flex-row justify-center items-center space-x-4 flex-1 flex-grow hidden">
            {data.prismicHeader.data.header_item.slice(0, 3).map((item) => (
              <Link to={item.link_to}>
                <li className="md:text-sm lg:text-base font-base uppercase text-gray-400">
                  {item.navigation_item.text}
                </li>
              </Link>
            ))}
            {data.prismicHeader.data.header_item.slice(3, 4).map((item) => (
              <Link to={item.link_to}>
                <li className="md:text-sm lg:text-base font-medium uppercase text-yellow-400">
                  {item.navigation_item.text}
                </li>
              </Link>
            ))}
          </ul>

          <div className = "flex flex-row justify-end flex-1">
            <button className="" onClick={() => setOpen(true)}>
              <MenuIcon className="h-6 text-gray-500 ml-auto" />
            </button>
          </div>
        </div>
      </nav>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="nav"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-900/70 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 max-w-full flex shadow-2xl">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-gray-800 shadow-xl overflow-y-scroll">
                    <div className="flex flex-row justify-between px-8">
                      <LogoSVG />
                      <button className="" onClick={() => setOpen(false)}>
                        <XIcon className="h-6 text-gray-500" />
                      </button>
                    </div>
                    <div className="px-4 sm:px-6">
                      <ul className="flex flex-col px-4 pt-12 divide-y-[0.5px] divide-gray-700">
                        {data.prismicHeader.data.header_item.map((item) => (
                          <Link to={item.link_to}>
                            <li className="text-gray-300 text-base font-light py-[10px] lg:py-3">
                              {item.navigation_item.text}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Header;
